<aside class="main-sidebar">

    <section class="sidebar">

        <div class="user-panel">
            <div class="pull-left image">
                <img src="assets/img/user1.jpg" class="img-circle" alt="User Image">
            </div>
            <div class="pull-left info">
                <p>{{authService.getLoggedUsername()}}</p>
                <a href="#">
                    <i class="fa fa-circle text-success"></i> Online</a>
            </div>
        </div>

        <!-- ADMIN AND SUPER ADMIN -->
        <ul *ngIf="authorizeService.IsAdmin() || authorizeService.IsSuperAdmin()" class="sidebar-menu"
            data-widget="tree">
            <li routerLinkActive="active">
                <a routerLink="/home" class="mobile_menu">
                    <img src="../../../assets/images/icons/side_menu/home.svg" alt="">
                    <span>Home</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <!-- <a routerLink="/dashboard"   data-toggle="push-menu"> -->
                <a routerLink="/dashboard" class="mobile_menu">
                    <img src="../../../assets/images/icons/side_menu/dashboard.svg" alt="">
                    <span>Dashboard</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/account" class="mobile_menu">
                    <img src="../../../assets/images/icons/side_menu/account.svg" alt="">
                    <span>Account</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/daily-report" class="mobile_menu">
                    <img src="../../../assets/images/icons/side_menu/daily_report.svg" alt="">
                    <span>Daily Report</span>
                </a>
            </li>

            <!-- <li routerLinkActive="active">
                    <a routerLink="/wasa-recharge-request" class="mobile_menu">
                        <i class="fa fa-calendar-minus-o"></i>
                        <span>Recharge Request</span>
                    </a>
                </li> -->

            <li routerLinkActive="active">
                <a routerLink="/recharge" class="mobile_menu">
                    <img src="../../../assets/images/icons/side_menu/recharge.svg" alt="">
                    <span>Recharge</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/bkashtransaction" class="mobile_menu">
                    <img src="../../../assets/images/icons/side_menu/bkash_transaction.svg" alt="">
                    <span>BKash Transaction</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/upaytransaction" class="mobile_menu">
                    <img src="../../../assets/images/icons/side_menu/bkash_transaction.svg" alt="">
                    <span>Upay Transaction</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/water-transaction" class="mobile_menu">
                    <img src="../../../assets/images/icons/side_menu/water_transaction.svg" alt="">
                    <span>Water Transaction</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/card" class="mobile_menu">
                    <img src="../../../assets/images/icons/side_menu/card.svg" alt="">
                    <span>Card</span>
                </a>
            </li>

            <li class="treeview" [class.menu-open]="isSettingOpen">
                <button (click)="openSettingMenu()">
                    <img src="../../../assets/images/icons/side_menu/settings.svg" alt="">
                    <span class="px-3">Setting</span>
                    <span class="pull-right-container">
                        <i class="fa fa-angle-left pull-right"></i>
                    </span>
                </button>
                <ul class="treeview-menu">
                    <li routerLinkActive="active">
                        <a routerLink="/setting/zone" class="mobile_menu">
                            <img src="../../../assets/images/icons/side_menu/zone.svg" alt="" class="mr-2">
                            Zone</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/setting/vendor" class="mobile_menu">
                            <img src="../../../assets/images/icons/side_menu/vendor.svg" alt="">
                            Vendor</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/setting/booth" class="mobile_menu">
                            <img src="../../../assets/images/icons/side_menu/pump.svg" alt="">
                            Pump</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/setting/device" class="mobile_menu">
                            <img src="../../../assets/images/icons/side_menu/atm.svg" alt="">
                            ATM</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/setting/cost" class="mobile_menu">
                            <img src="../../../assets/images/icons/side_menu/cost.svg" alt="">
                            Cost</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/setting/user" class="mobile_menu">
                            <img src="../../../assets/images/icons/side_menu/user.svg" alt="">
                            User</a>
                    </li>
                </ul>
            </li>

            <li class="treeview" [class.menu-open]="isLogOpen">
                <button (click)="openLogMenu()">
                    <img src="../../../assets/images/icons/side_menu/log.svg" alt="">
                    <span class="px-3">Log</span>
                    <span class="pull-right-container">
                        <i class="fa fa-angle-left pull-right"></i>
                    </span>
                </button>
                <ul class="treeview-menu">

                    <li routerLinkActive="active">
                        <a routerLink="/Log/DashboardAuditLog" class="mobile_menu">
                            <img src="../../../assets/images/icons/side_menu/dashboard_audit_log.svg" alt="">
                            Dashboard AuditLog </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/Log/UserHistory" class="mobile_menu">
                            <img src="../../../assets/images/icons/side_menu/user_history.svg" alt="">
                            User History</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/Log/DeviceLog" class="mobile_menu">
                            <img src="../../../assets/images/icons/side_menu/device_audit_log.svg" alt="">
                            Device AuditLog</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/Log/LoginLog" class="mobile_menu">
                            <img src="../../../assets/images/icons/side_menu/login_log.svg" alt="">
                            Login Log</a>
                    </li>

                    <!-- <li routerLinkActive="active" >
                                <a routerLink="/Log/ServerResponseLog" class="mobile_menu">
                                    <i class="fa fa-history" aria-hidden="true"></i> Server Response Log</a>
                            </li> -->
                    <!-- <li routerLinkActive="active" >
                                <a routerLink="/Log/Request-Response-Dispense-Count" class="mobile_menu">
                                    <i class="fa fa-history" aria-hidden="true"></i> Log Analysis</a>
                            </li> -->

                    <li routerLinkActive="active">
                        <a routerLink="/Log/BkashpayloadLog" class="mobile_menu">
                            <img src="../../../assets/images/icons/side_menu/bkash_transaction.svg" alt="">
                            BkashpayloadLog</a>
                    </li>

                    <li routerLinkActive="active">
                        <a routerLink="/Log/UpaypayloadLog" class="mobile_menu">
                            <img src="../../../assets/images/icons/side_menu/bkash_transaction.svg" alt="">
                            UpaypayloadLog</a>
                    </li>
                    <!-- <li routerLinkActive="active">
                            <a routerLink="/Log/ExceptionLog" class="mobile_menu">
                                <i class="fa fa-industry"></i> ExceptionLog</a>
                        </li> -->

                </ul>
            </li>

            <li class="treeview" [class.menu-open]="isReportOpen">
                <button class="mobile_menu" (click)="openReportMenu()">
                    <img src="../../../assets/images/icons/side_menu/report.svg" alt="">
                    <span class="px-3">Report</span>
                    <span class="pull-right-container">
                        <i class="fa fa-angle-left pull-right"></i>
                    </span>
                </button>
                <ul class="treeview-menu">
                    <li routerLinkActive="active">
                        <a routerLink="/report/atm-water-dispensed" class="mobile_menu">
                            <img src="../../../assets/images/icons/side_menu/atm_wise_water_dispense.svg" alt="">
                            ATM wise Water Dispense</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/report/customer-water-dispensed" class="mobile_menu">
                            <img src="../../../assets/images/icons/side_menu/customer_wise_water_dispense.svg" alt="">
                            Customer wise Water Dispense </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/report/recharge-revenue" class="mobile_menu">
                            <img src="../../../assets/images/icons/side_menu/pump_recharge.svg" alt="">
                            Pump Recharge </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/report/registered-customer" class="mobile_menu">
                            <img src="../../../assets/images/icons/side_menu/registered_customer.svg" alt="">
                            New Cards Sold</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/report/customer-recharge" class="mobile_menu">
                            <img src="../../../assets/images/icons/side_menu/customer_recharge.svg" alt="">
                            Customer recharge </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/report/customer-dispense" class="mobile_menu">
                            <img src="../../../assets/images/icons/side_menu/customer_wise_water_dispense.svg" alt="">
                            Customer water dispense </a>
                    </li>
                    <!-- <li routerLinkActive="active">
                            <a routerLink="/report/pump-wise-transaction" class="mobile_menu">
                                <i class="fa fa-file-o" aria-hidden="true"></i> Pump wise water transaction </a>
                        </li> -->
                </ul>
            </li>
        </ul>

        <!-- PUMP OPERATOR -->
        <ul *ngIf="authorizeService.IsPumpOperator()" class="sidebar-menu" data-widget="tree">
            <li routerLinkActive="active">
                <a routerLink="/home" class="mobile_menu">
                    <img src="../../../assets/images/icons/side_menu/home.svg" alt="">
                    <span>Home</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/dashboard">
                    <img src="../../../assets/images/icons/side_menu/dashboard.svg" alt="">
                    <span>Dashboard</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/account">
                    <img src="../../../assets/images/icons/side_menu/account.svg" alt="">
                    <span>Account</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/recharge">
                    <img src="../../../assets/images/icons/side_menu/recharge.svg" alt="">
                    <span>Recharge</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/card">
                    <img src="../../../assets/images/icons/side_menu/card.svg" alt="">
                    <span>Card</span>
                </a>
            </li>
        </ul>

        <!-- DATA OPERATOR -->
        <ul *ngIf="authorizeService.IsDataOperator()" class="sidebar-menu" data-widget="tree">
            <!-- <li routerLinkActive="active">
                    <a routerLink="/dashboard">
                        <img src="../../../assets/images/icons/side_menu/dashboard.svg" alt="">
                        <span>Dashboard</span>
                    </a>
                </li> -->
            <li routerLinkActive="active">
                <a routerLink="/home" class="mobile_menu">
                    <img src="../../../assets/images/icons/side_menu/home.svg" alt="">
                    <span>Home</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/account">
                    <img src="../../../assets/images/icons/side_menu/account.svg" alt="">
                    <span>Account</span>
                </a>
            </li>
        </ul>

        <!-- GOVT ADMIN -->
        <ul *ngIf="authorizeService.IsGovtAdmin()" class="sidebar-menu" data-widget="tree">
            <li routerLinkActive="active">
                <a routerLink="/home" class="mobile_menu">
                    <img src="../../../assets/images/icons/side_menu/home.svg" alt="">
                    <span>Home</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/dashboard">
                    <img src="../../../assets/images/icons/side_menu/dashboard.svg" alt="">
                    <span>Dashboard</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/wasa-recharge-request" class="mobile_menu">
                    <img src="../../../assets/images/icons/side_menu/recharge.svg" alt="">
                    <span>Recharge Request</span>
                </a>
            </li>
        </ul>

    </section>
</aside>